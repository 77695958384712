
import Vue from "vue";
import { emergencyMapActions } from "@/store/modules/emergency";
import { Session } from "@/helpers/processSession";
interface IComponentData {
  defaultTitle: string;
  checkingMaintenanceStatus: boolean;
  maintenanceComplete: boolean;
  initializing: boolean;
}
export default Vue.extend({
  data(): IComponentData {
    return {
      defaultTitle:
        "Sorry! We are currently undergoing maintenance. Try again later",
      checkingMaintenanceStatus: false,
      maintenanceComplete: false,
      initializing: false
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    ...emergencyMapActions(["checkEmergency"]),
    async checkEmergencyMaintenanceStatus(): Promise<void> {
      const session = new Session();
      try {
        this.checkingMaintenanceStatus = true;
        const res = await this.checkEmergency();
        const {
          isInFiservScheduledMaintenanceMode = false,
          isInEmergencyMaintenanceMode = false
        } = res;
        this.maintenanceComplete =
          !isInFiservScheduledMaintenanceMode && !isInEmergencyMaintenanceMode;
        if (!this.maintenanceComplete) {
          this.$appNotifyError(this.defaultTitle);
        }

        const emergencyModePageReloaded =
          session.get("emergencyModePageReloaded") === true;

        if (!emergencyModePageReloaded) {
          session.set("emergencyModePageReloaded", true);
          window.location.reload();
        }
      } catch {
        if (!this.initializing) {
          this.maintenanceComplete = false;
          this.$appNotifyError(this.defaultTitle);
        }
      } finally {
        this.checkingMaintenanceStatus = false;
      }
    },
    async initialize(): Promise<void> {
      try {
        this.initializing = true;
        await this.checkEmergencyMaintenanceStatus();
      } catch {
        return;
      } finally {
        this.initializing = false;
      }
    }
  },
  computed: {
    title(): string {
      return this.$route.query.message
        ? (this.$route.query.message as string)
        : this.defaultTitle;
    }
  }
});
